import React from 'react'
import { Box, Image } from '@chatterbug/aaron'
import check from './check-large.svg'
import LocalizedLink from 'src/components/LocalizedLink/LocalizedLink'
import { useTranslate } from 'react-polyglot'
import { useLocale } from 'src/lib/I18nContext'

const FeatureItem: React.FC<{ text: string }> = ({ text }) => (
  <Box display="flex" alignItems="flex-start" gridGap="1rem">
    <Image
      src={check}
      width={24}
      height={24}
      alt="Check"
      sx={{ flex: '0 0 24px' }}
    />
    <Box fontSize="18px" fontWeight="regular" lineHeight="1.3" color="gray.900" dangerouslySetInnerHTML={{ __html: text }} />
  </Box>
)

const BildungsurlaubFeatures: React.FC = () => {
  const t = useTranslate()
  const locale = useLocale()
  const features = [
    t('Officially recognized by German States'),
    t('<strong>5</strong> and <strong>10-day</strong> courses available for <strong>all levels</strong>'),
    t('<strong>45-minute</strong> 1:1 classes with trained tutors'),
    t('Personalized self-study as part of course'),
  ]

  return (
    <Box
      maxWidth="564px"
      width="100%"
      mb="2rem"
      zIndex={10}
      position="relative"
    >
      <Box
        display="grid"
        gridTemplateColumns={{ _: '1fr', tabletWide: '1fr 1fr' }}
        gridGap="1.5rem"
        boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
        bg="white"
        borderRadius="16px"
        padding={locale === 'de' ? '32px' : '32px 41px'}
      >
        {features.map((text, index) => (
          <FeatureItem key={index} text={text} />
        ))}
      </Box>
      <Box mt="2rem" padding="0 20px">
        <Box fontSize="18px" fontWeight="600" color="gray.800" lineHeight="1.3">
          {t(
            'Chatterbug offers officially recognized Educational Leave Courses for employees in Germany, allowing you to transform your language skills while taking paid time off work.'
          )}
        </Box>
        <Box fontSize="18px" fontWeight="regular" color="blue.500" mt="1rem">
          {t('Want to know how it works?')}{' '}
          <LocalizedLink to="/bildungsurlaub#faq">
            {t('Read our FAQ section below!')}
          </LocalizedLink>
        </Box>
      </Box>
    </Box>
  )
}

export default BildungsurlaubFeatures
