import { Box, Flex, Heading } from '@chatterbug/aaron'
import React, { useState, useEffect, useMemo } from 'react'
import Button, { BUTTON_VARIANTS } from '../../components/Button/Button'
import {
  Input,
  Select,
  SelectItem,
  Textarea,
  DateTime,
} from '../../components/CTAForm/FormElements'
import { useTranslate } from 'react-polyglot'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useLocale } from '../../lib/I18nContext'
import { LanguageCode } from '../../types'
import { BildungsurlaubSubmittedModal } from './BildungsurlaubSubmittedModal'
import faqData from '../../../cms/bildungsurlaub/map-faq_de-list-map-title-wie-funktionieren-die-bildungsurlaubskurse-von-chatterbug-content-jeden-tag-nehmen-sie-an-5-live-lessons-auf-der-chatterbug-plattform-teil-dabei-handelt-es-sich-um-45-minütige-online-videositzungen-mit-ein.json'

type Inputs = {
  fullName: string
  workEmail: string
  learningLanguage?: LanguageCode
  startDate: string
  courseLength?: string
  germanState?: string
  comments: string
}

const BildungsurlaubForm = () => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    resetField,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      fullName: '',
      workEmail: '',
      learningLanguage: undefined,
      startDate: undefined,
      courseLength: "",
      germanState: "",
      comments: '',
    },
  })
  const locale = useLocale()
  const selectedLanguage = watch('learningLanguage')
  const selectedState = watch('germanState')

  // Get available states based on selected language
  const availableStates = useMemo(
    () =>
      selectedLanguage
        ? faqData.states_per_language.find(
            (l) => l.language === selectedLanguage
          )?.states || []
        : [],
    [selectedLanguage]
  )

  // Reset state if selected state is not available in new language
  useEffect(() => {
    if (selectedState && availableStates.length > 0) {
      const stateExists = availableStates.some(
        (stateObj) => stateObj.state === selectedState
      )
      if (!stateExists) {
        resetField('germanState')
      }
    }
  }, [selectedLanguage, availableStates, selectedState, resetField])

  const [submitted, setSubmitted] = useState(false)
  const onSubmit: SubmitHandler<Inputs> = (data) =>
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        ...data,
        lang: locale,
        'form-name': 'bildungsurlaub-form',
      }).toString(),
    })
      .then(async (r) => {
        if (!r.ok) {
          throw new Error(await r.text())
        }
      })
      .then(() => setSubmitted(true))
      .then(() => reset())
      .catch(() => {})

  const t = useTranslate()
  const sixWeeksFromNow = new Date()
  sixWeeksFromNow.setDate(sixWeeksFromNow.getDate() + 42) // 6 weeks = 42 days

  const sixMonthFromNow = new Date()
  sixMonthFromNow.setDate(sixMonthFromNow.getDate() + 180)


  // Format dates to YYYY-MM-DD for the date input
  const minDate = sixWeeksFromNow.toISOString().split('T')[0]
  const maxDate = sixMonthFromNow.toISOString().split('T')[0]

  const excludedDates = faqData.excluded_dates.map((date) => date.date)

  // Function to check if a date is a Monday
  const isMonday = (date: Date) => {
    return date.getDay() === 1 // 1 represents Monday (0 is Sunday)
  }

  return (
    <Box maxWidth="29.6rem">
      <BildungsurlaubSubmittedModal open={submitted} setOpen={setSubmitted} />

      <Box
        as="form"
        name="bildungsurlaub-form"
        backgroundColor="white100"
        px="1.25rem"
        py="2.25rem"
        display="flex"
        flexDirection="column"
        sx={{
          gap: '1rem',
          borderRadius: '1rem',
          boxShadow:
            '0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 2px 4px 0px rgba(0, 0, 0, 0.03), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 16px 0px rgba(0, 0, 0, 0.03)',
        }}
        method="POST"
        data-netlify="true"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="lang" />
        <Box px={locale === 'de' ? '0' : '24px'} pb="16px">
          <Heading variant="h3" textAlign="left">
            {t('Register for an Educational Leave Course')}
          </Heading>
          <Box
            fontSize={16}
            textAlign="left"
            lineHeight="1.3"
            pt=".5rem"
            sx={{
              borderRadius: '.5rem',
            }}
          >
            {t(
              "Submit the form to request your course. We'll get back to you to confirm dates, provide documents and organize payment."
            )}
          </Box>
        </Box>
        <Flex flexDirection="column">
          <Controller
            name="fullName"
            control={control}
            rules={{ required: t('This field is required') }}
            render={({ field }) => (
              <Input
                label={`${t('Full Name')}*`}
                placeholder={t('Petra Colins')}
                error={errors.fullName}
                {...field}
              />
            )}
          />
          <Controller
            name="workEmail"
            control={control}
            rules={{ required: t('This field is required') }}
            render={({ field }) => (
              <Input
                label={`${t('Work Email')}*`}
                placeholder={t('petra@company.com')}
                error={errors.workEmail}
                {...field}
              />
            )}
          />

          <Controller
            name="learningLanguage"
            control={control}
            rules={{ required: t('This field is required') }}
            render={({ field }) => (
              <Select
                label={`${t('Learning Language')}*`}
                placeholder={t('Please select')}
                error={errors.learningLanguage}
                {...field}
              >
                <SelectItem value="en">{t('English')}</SelectItem>
                <SelectItem value="de">{t('German')}</SelectItem>
              </Select>
            )}
          />

          <Controller
            name="startDate"
            control={control}
            rules={{ required: t('This field is required') }}
            render={({ field: { value, onChange, ...field } }) => (
              <DateTime
                label={`${t('Preferred Course Starting Date')}*`}
                error={errors.startDate}
                value={value || ''}
                onChange={onChange}
                min={minDate}
                max={maxDate}
                excludedDates={excludedDates}
                filterDate={isMonday}
                {...field}
              />
            )}
          />

          <Controller
            name="courseLength"
            control={control}
            rules={{ required: t('This field is required') }}
            render={({ field }) => (
              <Select
                label={`${t('Course Length')}*`}
                placeholder={t('Please select')}
                error={errors.courseLength}
                {...field}
              >
                <SelectItem value="5">{t('5 days')}</SelectItem>
                <SelectItem value="10">{t('10 days')}</SelectItem>
              </Select>
            )}
          />

          <Controller
            name="germanState"
            control={control}
            rules={{ required: t('This field is required') }}
            render={({ field }) => (
              <Select
                label={`${t('German State')}*`}
                placeholder={
                  selectedLanguage
                    ? t('Please select')
                    : t('Please select a course language first')
                }
                error={errors.germanState}
                disabled={!selectedLanguage}
                {...field}
              >
                {availableStates.map((stateObj) => (
                  <SelectItem key={stateObj.state} value={stateObj.state}>
                    {t(stateObj.state)}
                  </SelectItem>
                ))}
              </Select>
            )}
          />

          <Controller
            name="comments"
            control={control}
            render={({ field }) => (
              <Textarea
                label={t('Questions or Comments?')}
                placeholder={t('Type here...')}
                {...field}
              />
            )}
          />
        </Flex>
        <Button
          variant={BUTTON_VARIANTS.primary}
          type="submit"
          width={{
            _: '100%',
          }}
          mt="16px"
          sx={{
            background: '#E61C54',
            color: 'white',
            border: 'none',
          }}
          height={{ _: '44px' }}
          href={null}
          label={t('Submit')}
        />
      </Box>

      <Box
        variant="text.paragraph"
        mt="16px"
        px="3rem"
        color="#95989D"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t(
            "By submitting this form you agree to Chatterbug's Privacy Policy",
            {
              a: `<a href="https://chatterbug.com/${locale}/legal/privacy" target="_blank" style="color: inherit">`,
              a_end: '</a>',
            }
          ),
        }}
      />
    </Box>
  )
}

export { BildungsurlaubForm }
