import React, { MouseEventHandler } from 'react'
import { Box } from '@chatterbug/aaron'

import { usePageThemeContext } from 'src/lib/pageThemeContext'

export type Props = {
  label: string
  href: string
  external?: boolean
  onClick?: MouseEventHandler
}

const ExtraLink: React.FC<Props> = ({
  label,
  href,
  external,
  onClick,
  ...other
}) => {
  const pageTheme = usePageThemeContext()

  return (
    <Box
      as="a"
      href={href}
      target={external ? '_blank' : undefined}
      rel={external ? 'noopener' : undefined}
      alignItems="center"
      variant="text.buttonTitle"
      className="extra-link"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        fontSize: 'xxs',
        letterSpacing: '0.96px',
        color: pageTheme.extraLinkAccentColor || pageTheme.accentColor,
        textDecoration: 'none',
        transition: 'color 0.3s ease',
        ':hover, :active, :focus': {
          textDecoration: 'none',
          color:
            pageTheme.extraLinkAccentHoverColor || pageTheme.accentHoverColor,
        },
      }}
      onClick={onClick}
      {...other}
    >
      <Box mr="10px" pb="2px">
        {label}
      </Box>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.98011 3.02055C8.78484 2.82528 8.78484 2.5087 8.98011 2.31344C9.17537 2.11818 9.49195 2.11818 9.68721 2.31344L15.0205 7.64677C15.1143 7.74054 15.167 7.86772 15.167 8.00033C15.167 8.13293 15.1143 8.26011 15.0205 8.35388L9.68721 13.6872C9.49195 13.8825 9.17537 13.8825 8.98011 13.6872C8.78484 13.492 8.78484 13.1754 8.98011 12.9801L13.4599 8.50033H1.66699C1.39085 8.50033 1.16699 8.27647 1.16699 8.00033C1.16699 7.72418 1.39085 7.50033 1.66699 7.50033H13.4599L8.98011 3.02055Z"
          fill="currentColor" // color or hoverColor
        />
      </svg>
    </Box>
  )
}

export default ExtraLink
