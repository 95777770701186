import React, { useState } from 'react'
import { Box, Flex, Image } from '@chatterbug/aaron'
import PageContainer from 'src/components/PageContainer/PageContainer'
import qIllustration from './Q-illustration.svg'
import aIllustration from './A-Illustration.svg'
import plusIcon from './Plus.svg'
import minusIcon from './Minus.svg'
import { useLocale } from 'src/lib/I18nContext'
import { SiteLocale } from 'src/types'
import faqData from '../../../cms/bildungsurlaub/map-faq_de-list-map-title-wie-funktionieren-die-bildungsurlaubskurse-von-chatterbug-content-jeden-tag-nehmen-sie-an-5-live-lessons-auf-der-chatterbug-plattform-teil-dabei-handelt-es-sich-um-45-minütige-online-videositzungen-mit-ein.json'
import { useTranslate } from 'react-polyglot'

type FAQItem = {
  question: string
  answer: string
}

const BildungsurlaubFAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null)
  const locale = useLocale()
  const t = useTranslate()

  const convertContentToHTML = (content: string): string => {
    // Process Markdown headers (# Header)
    content = content.replace(/^(#{1,6})\s+(.+)$/gm, (match, hashes, text) => {
      const level = hashes.length;
      return `<h${level}>${text.trim()}</h${level}>`;
    });

    // Process bold text (**text** or __text__)
    content = content.replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>');

    // Process links [text](url)
    content = content.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');

    // Process inline code `code`
    content = content.replace(/`([^`]+)`/g, '<code>$1</code>');

    // Process code blocks
    content = content.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');

    // Check if content is a numbered list (starts with "1.")
    if (content.trim().match(/^1\./)) {
      return `<ol>${content.split(/\d+\./).filter(Boolean).map(item => 
        `<li>${item.trim()}</li>`
      ).join('')}</ol>`;
    }

    // Handle bullet points
    if (content.includes('*')) {
      // First, split content into main bullet points
      const mainPoints = content.split('\n').map(line => line.trim()).filter(Boolean);
      let html = '<ul>';

      mainPoints.forEach(point => {
        // If the point contains multiple asterisks, it's a nested list
        if ((point.match(/\*/g) || []).length > 1) {
          // Split the point into its components
          const [mainText, ...subPoints] = point.split('*').map(p => p.trim()).filter(Boolean);
          
          if (mainText) {
            html += `<li>${mainText}`;
            if (subPoints.length > 0) {
              html += '<ul>';
              subPoints.forEach(subPoint => {
                html += `<li>${subPoint}</li>`;
              });
              html += '</ul>';
            }
            html += '</li>';
          }
          } else {
          // Regular bullet point
          const text = point.replace(/^\*\s*/, '');
          if (text) {
            html += `<li>${text}</li>`;
          }
        }
      });
      
      html += '</ul>';
      return html;
    }

    // If no list markers found, return content as is
    return `<p>${content}</p>`;
  };

  const faqItems: FAQItem[] = (locale === SiteLocale.de ? faqData.faq_de : faqData.faq_en).map(item => ({
    question: item.title,
    answer: convertContentToHTML(item.content)
  }))

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <Box as="section" py="60px" id="faq" px={{ _: '0', tablet: '42px' }}>
      <PageContainer>
        <Box position="relative">
          <Image
            src={qIllustration}
            width={133}
            height={133}
            alt="Check"
            display={{ _: 'none', tablet: 'block' }}
            sx={{
              position: 'absolute',
              top: "155px",
              left: 0,
            }}
          />
          <Image
            src={aIllustration}
            width={133}
            height={133}
            alt="Check"
            display={{ _: 'none', tablet: 'block' }}
            sx={{
              position: 'absolute',
              bottom: "80px",
              right: 0,
            }}
          />
          <Box as="h2" variant="text.h2" textAlign="center" mb="50px">
            {t('Frequently Asked Questions')}
          </Box>
          <Box maxWidth="956px" mx="auto" display="flex" flexDirection="column" gridGap="10px" zIndex={10} position="relative">
            {faqItems.map((item, index) => (
              <Box
                key={index}
                bg="white100"
                borderRadius="16px"
                boxShadow="overlay"
                overflow="hidden"
              >
                <Flex
                  as="button"
                  onClick={() => handleToggle(index)}
                  width="100%"
                  padding="20px 24px"
                  alignItems="center"
                  justifyContent="space-between"
                  bg="#FAFAFA"
                  sx={{
                    cursor: 'pointer',
                    border: 'none',
                    textAlign: 'left',
                    transition: 'background-color 0.3s ease',
                    ':hover': {
                      bg: 'gray95',
                    },
                  }}
                >
                  <Box
                    as="span"
                    pr="2x"
                    variant="text.h4"
                    fontWeight="semiBold"
                  >
                    {item.question}
                  </Box>
                  <Image
                    src={openIndex === index ? minusIcon : plusIcon}
                    width={24}
                    height={24}
                    alt={openIndex === index ? "Close" : "Open"}
                    sx={{
                      flexShrink: 0,
                    }}
                  />
                </Flex>
                <Box
                  height={openIndex === index ? 'auto' : 0}
                  overflow="hidden"
                  bg="#FAFAFA"
                  sx={{
                    transition: 'height 0.3s ease',
                  }}
                >
                  <Box p="3x" pt={0} variant="text.paragraph" dangerouslySetInnerHTML={{ __html: item.answer }}
                    sx={{
                      "& li+li": {
                        mt: "8px"
                      }
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </PageContainer>
    </Box>
  )
}

export default BildungsurlaubFAQ
