import {PageContext} from "../../types";
import React from "react";
import {PageProps} from "gatsby";
import Layout from "src/components/Layout/Layout";
import Bildungsurlaub from "src/sections/Bildungsurlaub/Bildungsurlaub";
import Head from "../../components/Head/Head";
import {useTranslate} from "react-polyglot";

type DataProps = Record<string, never>

const BusinessPage: React.FC<PageProps<DataProps, PageContext>> = ({
                                                                     pageContext,
                                                                   }) => {
  const t = useTranslate()

  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head
        pageContext={pageContext}
        titleOverride={t('Bildungsurlaub - Chatterbug')}
      />
      <Bildungsurlaub pageContext={pageContext}/>
    </Layout>
  )
}
export default BusinessPage