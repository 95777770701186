import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Box, Heading, Image } from "@chatterbug/aaron";
import successImage from "src/components/CTAForm/assets/success.svg"
import closeImage from "src/components/CTAForm/assets/close.svg"
import { useTranslate } from "react-polyglot";

const BildungsurlaubSubmittedModal = ({ open, setOpen }: { open: boolean, setOpen: (value: boolean) => void }) => {
  const t = useTranslate()
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay style={{
          position: "fixed",
          left: "0",
          top: "0",
          right: "0",
          bottom: "0",
          zIndex: "50",
          background: "radial-gradient(59.26% 50.00% at 50.00% 50.00%, rgba(0, 0, 0, 0.00) 0%, #000 100%)",
        }}/>
        <Dialog.Content style={{
          backgroundColor: "#ffffff",
          maxWidth: "33.125rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "1rem",
          position: "fixed",
          left: "50%",
          top: "50%",
          zIndex: "50",
          width: "100%",
          transform: "translate(-50%, -50%)",
          padding: "1.5rem 1.5rem 3rem",
          outline: "none",
        }}>
          <Image src={successImage} width="167px" height="167px"/>
          <Heading
            as="h3"
            style={{
              marginTop: ".44rem",
              marginBottom: "0",
            }}
          >
            {t("Success!")}
          </Heading>
          <Box
            variant="text.paragraph"
            textAlign="center"
            mt="1.5rem"
          >
            {t("The team will be in touch shortly. In the meantime, please set up a Chatterbug account. Feel free to explore the platform!")}
          </Box>
          <Box
            as="a"
            href="https://app.chatterbug.com/signup/"
            p="1rem"
            mt="3rem"
            backgroundColor="blue42"
            color="white100"
            textTransform="uppercase"
            variant="text.buttonTitle"
            sx={{
              borderRadius: "2.5rem",
              textDecoration: "none",
              lineHeight: "1",
              transition: "all ease-in-out .3s",
              "&:hover": {
                backgroundColor: "blue30",
              }
            }}
          >
            {t("Sign up")}
          </Box>
          <Dialog.Close
            style={{
              appearance: "none",
              position: "absolute",
              top: "1.5rem",
              right: "1.5rem",
              padding: "0",
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            <Image src={closeImage} width="1.5rem" height="1.5rem"/>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export { BildungsurlaubSubmittedModal }