import React from 'react'
import { useLocale } from 'src/lib/I18nContext'
import { getInitiallySelectedL2Code } from 'src/lib/localization'
import { LessonsNavigationItemName, PageContext, SiteLocale } from 'src/types'
import { BusinessThemeProvider } from 'src/lib/pageThemeContext'
import { Box, Image } from '@chatterbug/aaron'
import { BildungsurlaubForm } from './BildungsurlaubForm'
import BildungsurlaubFeatures from './BildungsurlaubFeatures'
import BildungsurlaubHero from './BildungsurlaubHero'
import BildungsurlaubFAQ from './BildungsurlaubFAQ'
import PageContainer from 'src/components/PageContainer/PageContainer'
import Header from 'src/components/Header/Header'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import SectionSeparator from '../SectionSeparator/SectionSeparator'
import OurMethod from '../Lessons/subcomponents/OurMethod/OurMethod'
import WhyChatterbug from '../WhyChatterbug/WhyChatterbug'
import background from '../Lessons/assets/background.svg'
import NotEligibleCTA from './NotEligibleCTA'
import rocket from './rocket.svg'
import rocketDe from './rocket-de.svg'

export type Props = {
  pageContext: PageContext
}

const Bildungsurlaub: React.FC<Props> = ({ pageContext }) => {
  const locale = useLocale()
  const initialL2Code = getInitiallySelectedL2Code(locale)

  return (
    <BusinessThemeProvider>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(110deg, rgba(255,90,104,1) 0%, rgba(255,201,119,0.7531745119922969) 100%);',
          }}
        />
        <Box
          top={{ _: '700px', tabletWide: '480px' }}
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            width: '100%',
            height: '70%',
            clipPath: 'ellipse(150% 100% at 50% 100%)',
            background: '#fafafa',
          }}
        />
        <PageContainer>
          <Box position="relative">
            <Header
              backgroundType="dark"
              l2Code={initialL2Code}
              canonicalPath={pageContext.canonicalPath}
              enabledLocaleCodes={[SiteLocale.en, SiteLocale.de]}
              items={[]}
              secondaryItemsGroup={{
                items: [],
              }}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={{ _: 'column', tablet: 'row' }}
              gridGap="52px"
              alignItems="flex-start"
              pb="1rem"
            >
              <Box position="relative">
                <BildungsurlaubHero />
                <Image
                  src={locale === SiteLocale.de ? rocketDe : rocket}
                  alt="Bildungsurlaub"
                  position="absolute"
                  top={{ _: '300px', tabletWide: '180px' }}
                  left="0"
                />
                <BildungsurlaubFeatures />
              </Box>
              <Box width={{ _: "100%", tablet: "500px" }}>
                <BildungsurlaubForm />
              </Box>
            </Box>
          </Box>
        </PageContainer>
      </Box>

      <BildungsurlaubFAQ />

      <Box
        as="section"
        id={LessonsNavigationItemName.lessonsFeatures}
        mt={-32}
        position="relative"
        sx={{ zIndex: 2 }}
      >
        <FullWidthBackground image={background} width={1439} height={741}>
          <Box pt="4x">
            <WhyChatterbug
              sectionName={LessonsNavigationItemName.bildungsurlaub}
            />
          </Box>
        </FullWidthBackground>
      </Box>
      <Box as="section" id={LessonsNavigationItemName.method} mt={88}>
        <SectionSeparator name={LessonsNavigationItemName.method} />
        <Box mt="3x">
          <OurMethod />
        </Box>
      </Box>

      <Box
        sx={{
          clipPath: 'ellipse(150% 100% at 50% 100%)',
          background:
            'linear-gradient(110deg, rgba(255,90,104,1) 0%, rgba(255,201,119,0.7531745119922969) 100%);',
        }}
        pt={70}
        pb={50}
      >
        <Box as="section" mx="auto" maxWidth="1200px" px="24px">
          <NotEligibleCTA />
        </Box>
      </Box>
    </BusinessThemeProvider>
  )
}

export default Bildungsurlaub
