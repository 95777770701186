import React from 'react'
import { Box } from '@chatterbug/aaron'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import { useTranslate } from 'react-polyglot'

const NotEligibleCTA: React.FC = () => {
  const t = useTranslate()
  return (
    <Box
      as="section"
      sx={{
        backgroundColor: 'white',
        borderRadius: '36px',
        padding: '32px',
        textAlign: 'center',
        maxWidth: '660px',
        margin: '0 auto',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        as="h2"
        sx={{
          fontSize: '28px',
          fontWeight: 'medium',
          color: 'gray.900',
          marginBottom: '24px',
          lineHeight: '1.2',
        }}
      >
        {t('Not Eligible for Educational Leave? Try Chatterbug as a Regular Learner!')}
      </Box>
      <Button
        label={t('START FREE TRIAL')}
        backgroundColor="#E61C54"
        sx={{
          '&:hover': {
            backgroundColor: '#E61C54',
          },
        }}
        color="white100"
        href="/signup"
        height="44px"
        variant={BUTTON_VARIANTS.brand}
      />
    </Box>
  )
}

export default NotEligibleCTA
