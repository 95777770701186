import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'
import Flag from 'src/components/Flag/Flag'
import { useTranslate } from 'react-polyglot'

type Props = {
  className?: string
}

const BildungsurlaubHero: React.FC<Props> = ({ className }) => {
  const t = useTranslate()
  return (
    <Box className={className} padding={{ _: '3rem 0 150px', tablet: "3rem 1rem 10rem" }}>
      <Flex flexDirection="column" maxWidth="1200px" mx="auto" width="100%">
        <Box mb="1rem">
          <Box
            bg="rgba(255, 206, 84, 0.8)"
            padding="8px 14px"
            display="inline-flex"
            gridGap="10px"
            alignItems="center"
            borderRadius="4px"
          >
            <Flag code="de" size="mini" />
            <Box as="span" fontSize="0.875rem" fontWeight="600" display="block" sx={{ textTransform: 'uppercase' }}>
              {t('FOR EMPLOYEES IN GERMANY (BILDUNGSURLAUB)')}
            </Box>
          </Box>
        </Box>

        <Box
          as="h1"
          fontSize={{ _: '34px', tabletWide: '42px' }}
          fontWeight="700"
          lineHeight={{ _: '1.1', tablet: "1.2" }}
          mb="1.5rem"
          color="white100"
          textAlign={{ _: 'center', tablet: 'left' }}
          dangerouslySetInnerHTML={{
            __html: t('Online Educational Leave<br />Courses with Chatterbug'),
          }}
        />

        <Box
          fontSize={['1.25rem', '1.5rem']}
          lineHeight="1.3"
          maxWidth="500px"
          color="white100"
          dangerouslySetInnerHTML={{
            __html: t(
              'Get time off work to learn <strong>English</strong> or <strong>German</strong> intensively, <strong>from anywhere.</strong>'
            ),
          }}
        />
      </Flex>
    </Box>
  )
}

export default BildungsurlaubHero
